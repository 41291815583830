<template>
  <Banner />

  <section class="card-section" v-if="card && card.display">
    <div class="container-light card-container">
      <i :class="`fal fa-${card.icon}`"></i>
      <p>
        <strong>{{card.header}}</strong><br /><span v-html="card.body"></span>
      </p>
    </div>
  </section>

  <section class="row content-centered frontpage-cols">
    <div class="col">
      <i class="far fa-2x fa-users"></i>
      <h3>Who we are</h3>
      <p>We are a community of fellow game development enthusiasts who want to get some hands-on experience making video games.</p>
    </div>

    <div class="col">
      <i class="far fa-2x fa-laptop-code"></i>
      <h3>What we do</h3>
      <p>We organize many events to help students improve at game development. Some examples are workshops, game dev teams and joining game jams together.</p>
    </div>

    <div class="col">
      <i class="far fa-2x fa-map-marked-alt"></i>
      <h3>Where we're from</h3>
      <p>We're a community with people from all over the world, but we're based in Eindhoven. Most of our activities are offline and happen at the TU/e.</p>
    </div>
  </section>

  <section class="middle">
    <h1 class="events-title">Interested? Check out one of our upcoming events!</h1>
    <EventsGrid :short="true" :centered="true" :past="false" />
  </section>

  <CTADiscord />

</template>

<script>
import Banner from '@/components/Banner.vue'
import EventsGrid from '@/components/EventsGrid.vue'
import CTADiscord from '@/components/CTADiscord.vue'

export default {
  name: 'Home',
  inject: ["createNotification"],
  components: {
    Banner,
    EventsGrid,
    CTADiscord
  },
  data() {
    return {
      card: this.$store.state.settings.homeCard,
    }
  },
  computed: {
    queryError() {
      return this.$route.query.error || null
    }
  },
  watch: {
    queryError: {
      immediate: true,
      handler(val) {
        switch (val) {
          case null:
            break;
          
          case 'no-permission':
            this.createNotification({
              type: 'error',
              title: 'Permission denied',
              message: 'You lack the permissions to view that page.'
            })
            break;
          
          case 'no-auth':
            this.createNotification({
              type: 'error',
              title: 'Permission denied',
              message: 'You have to be logged in to view that page.'
            })
            break;
        
          default:
            this.createNotification({
              type: 'error',
              title: 'Error',
              message: `An error occurred: ${val}`
            })
            break;
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.frontpage-cols {
  justify-content: center;
  gap: 6rem;

  .col {
    max-width: 320px;
  }

  h3 {
    margin: 1rem 0 12px;
  }

  p {
    word-spacing: 0.05em;
    letter-spacing: 0.01em;
    line-height: 1.5em;
  }
}

.events-title {
  text-align: center;
}

.events-grid {
  margin-top: 2rem;
}

@media screen and (max-width: 1100px) {
  .frontpage-cols {
    justify-content: space-between;
    gap: 2rem;
  }
}

@media screen and (max-width: 800px) {
  .frontpage-cols .col { max-width: 100%; }
}
</style>