<template>
  <a href="https://discord.gg/gyWPKSUBJc" target="_blank" class="button discord-button"><i class="fab fa-discord"></i> Join us on Discord!</a>
</template>

<script>
export default {
  name: "DiscordButton"
}
</script>
<style lang="scss">
.discord-button i { margin-right: 8px; }

.banner .discord-button,
.cta-banner .discord-button {
  background-color: #fff;
  color: var(--color-purple-light);
  border-color: #fff;

  &:hover {
    background-color: #fff;
    transform: scale(1.04);
  }
}
</style>