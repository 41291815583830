<template>
  <section class="banner">
    <img class="banner-image" src="../assets/game-dev.jpg" alt="" />
    <div class="banner-content">
      <h1>Develop games together</h1>
      <p>We’re a student-led community of passionate game development enthusiasts looking to learn and develop together.</p>
      <DiscordButton />
      <br />
      <div class="banner-card">
        <a href="https://www.tue.nl/en" target="_blank">
          <img src="/images/tue-large.png" alt="Eindhoven University of Technology" />
        </a>
        <p>Student team of Eindhoven University of Technology</p>
      </div>
    </div>
  </section>
</template>

<script>
import DiscordButton from '@/components/DiscordButton.vue'

export default {
  name: 'Banner',
  components: {
    DiscordButton
  }
}
</script>

<style lang="scss">
.banner {
  position: relative;
  height: 400px;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #292835;
    opacity: 0.9;
  }

  &-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .banner-content {
    position: absolute;
    left: 0;
    top: 50%;
    width: calc(100% - 2 * var(--page-padding));
    transform: translateY(-50%);
    margin: 0 var(--page-padding);
    z-index: 1;

    h1 { font-size: 2em; }
    h1, p {
      margin-bottom: 1.25rem;
    }
    p {
      max-width: 375px;
      line-height: 1.3;
    }
  }

  .banner-card {
    position: absolute;
    right: 0;
    top: -4rem;
    display: inline-block;
    padding: 1rem 1rem 1.25rem;
    border-radius: 6px;
    background-color: var(--color-bg-light);
    color: #c2c2c9;
    z-index: 1;

    img {
      margin-bottom: 4px;
      height: 36px;
    }

    p {
      max-width: 210px;
      font-size: 16px;
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 950px) {
  .banner {
    height: 500px;

    .banner-card {
      position: static;
      margin-top: 3rem;
    }
  }
}

@media screen and (max-width: 450px) {
  .banner {
    height: 600px;
  }
}
</style>