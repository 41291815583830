<template>
  <section class="cta-banner">
    <img src="../assets/banner-future.jpg" alt="" />
    <div class="banner-content">
      <h1>Join us today!</h1>
      <p>Would you like to become a member of Totem Game Dev? Join our Discord server by clicking the button below!</p>
      <DiscordButton />
    </div>
  </section>
</template>

<script>
import DiscordButton from '@/components/DiscordButton.vue'

export default {
  name: "CTADiscord",
  components: {
    DiscordButton
  }
};
</script>

<style scoped lang="scss">
.cta-banner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #292835;
    opacity: 0.9;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .banner-content {
    z-index: 1;

    h1 {
      font-size: 2em;
    }
    
    p {
      max-width: 70%;
      margin: 8px auto 1.25rem;
      font-size: 18px;
      font-style: italic;
      word-spacing: 0.05em;
      letter-spacing: 0.01em;
      line-height: 1.5em;
    }
  }
}
</style>